import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "./assets/theme/index.css";

import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ElementLocale from "element-ui/lib/locale";


Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);


import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

const messages = {
  en: {
    message: "hello",
    login:"login",
    tab: {
      relation: "Go Track",
      home: "Home",
    },
    home: {
      section1: {
        title: "Bro，your security expert",
        subTitle: "Solving blockchain Security Issues with AI and Data",
        describe: "Digital asset traceability｜security audit service｜on-chain tracking",
        contactUs: "Contact Us",
        caseApply:"Case"
      },
      section2: {
        title: "Digital Asset Traceability ",
        subTitle: "Restore the truth of the incident, find the suspects, and recover the stolen digital assets",
        info1: {
          num: "90%",
          name: "case detection",
          type: "Crimes within the team"
        },
        info2: {
          num: "65%",
          name: "case detection",
          type: "Domestic hackers commit crimes"
        },
        info3: {
          num: "$100",
          name: "Digital assets",
          type: "have been recovered"
        },
        decribe1: "No. 1 in the industry, with a market share of over 60% in major cases",
        decribe2: "Detected multiple transnational cases",
        decribe3: "Assisted the police in arresting 30+ suspects"
      },
      section3: {
        title: "Security Audit Service",
        subTitle: "Conduct a comprehensive security assessment of blockchain code to identify vulnerabilities and recommend fixes",
        decribe1: "vulnerabilities and recommend fixes.",
        decribe2: "DeFi Security Audit Service",
        decribe3: "NFT and Metaverse Security Audit Service",
        tip1: "Client Type",
        tip2: "Exchange",
        tip3: "Wallet company"
      },
      section4: {
        title: "GoTrack: On-chain tracking service",
        subTitle: "Millions of address labels, graph visualization, real-time tracking of digital assets",
        decribe1: "Real-time monitoring of blockchain transactions",
        decribe2: "Millions of Address Labels",
        decribe3: "Digital asset on-chain Graph",
        decribe4: "Assist the police in freezing digital assets",
        tip: "Support all on-chain tokens based on ETH, TRX, BSC, etc.",
        goTrack: "Go To Track",
      },
      section5: {
        title: "SUCCESS STORIES ",
        decribe1: "<strong  style='font-size:14px;line-height:24px;'>Web3 Security Services Clients：</strong><br /><span style='font-size:14px;line-height:24px;'>Exchange：<br />Coinex、Coinbean、Bitmax、 hotbtx、btcbox<br />Chain：Tron、wicc <br />Wallet: Coldlar <br /> DeFi: BXH、Accessifi、starfish <br />Metaverse：Starrynift</span>",
        decribe2: "<span style='font-size:14px;line-height:24px;'>In 2022, assisted the local police to destroy a multinational black production team, and helped the client recover millions of RMB losses. This case involved APT attacks, targeting large Internet companies, and using virtual assets to launder money</span>",
        decribe3: "<span style='font-size:14px;line-height:22px;'>Assisted a local police in investigating the world's second largest case (BXH theft case) in 2021. It is worth mentioning that the victims of this case have invited more than 10 security companies to participate, and Bit Jungle only took three days to provide high-value clues to characterize the case and obtained millions of RMB in rewards from the victims, which shows that Bit jungle is leading in the field of digital asset traceability</span>",
        decribe4: "<span style='font-size:14px;line-height:24px;'>In 2019, Bit Jungle assisted the police in a certain place to crack the USDT fake recharge case and recovered 10 million RMB worth of digital assets for the victims<br /><br />In 2021, assisted the local police in cracking the case of stealing digital assets using smart contracts, and helped users recover the stolen digital assets.</span>",
      }
    },
    ...enLocale,
  },
  zh: {
    message: "你好",
    login:"登录",
    tab: {
      relation: "链上追踪",
      home: "首页",
    },
    home: {
      section1: {
        title: "你身边的区块链安全专家",
        subTitle: "通过AI和数据解决区块链安全问题",
        describe: "数字资产溯源｜安全审计服务｜链上追踪",
        contactUs: "联系我们",
        caseApply:"案件提交"
      },
      section2: {
        title: "数字资产溯源",
        subTitle: "还原事件真相、找到嫌疑人、挽回被盗数字资产",
        info1: {
          num: "90%",
          name: "破案率",
          type: "团队内部作案"
        },
        info2: {
          num: "65%",
          name: "破案率",
          type: "团队黑客作案"
        },
        info3: {
          num: "$100",
          name: "金额",
          type: "已挽回数字资产"
        },
        decribe1: "行业第一，重大案件市场占有率60%以上",
        decribe2: "侦破多起跨国案件",
        decribe3: "协助警方抓获30+嫌疑人"
      },
      section3: {
        title: "安全审计服务",
        subTitle: "针对区块链代码进行全面的安全评估以识别漏洞和推荐修复方法",
        decribe1: "针对数字钱包和交易所进行风险评估",
        decribe2: "DeFi安全审计服务",
        decribe3: "NFT和元宇宙安全审计服务",
        tip1: "客户类型",
        tip2: "交易所",
        tip3: "钱包公司"
      },
      section4: {
        title: "GoTrack : 链上追踪服务",
        subTitle: "数百万地址标签、图谱可视化、实时追踪数字资产",
        decribe1: "实时监控区块链交易",
        decribe2: "数百万地址标签",
        decribe3: "资产链路图谱",
        decribe4: "协助警方冻结数字资产",
        tip: "支持所有基于 ETH 、 TRX 、BSC等链上代币",
        goTrack: "Go To Track",
      },
      section5: {
        title: "成功案例",
        decribe1: "<strong>Web3安全服务客户：</strong><br /><span style='line-height:29px;'>交易所：<br />Coinex、Coinbean、Bitmax、hotbtx、btcbox<br />链：Tron、wicc<br />钱包：Coldlar<br />DeFi: BXH、Accessifi、starfish<br />元宇宙：Starrynift</span>",
        decribe2: "<span style='line-height:29px;'>2022年协助某地警方打掉一跨国黑产团队，帮助客户挽回数百万人民币损失。此案件涉及APT攻击、针对大型互联网企业、使用虚拟资产洗钱</span>",
        decribe3: "<span style='line-height:29px;'>2021年协助某地警方侦办全球第二大案件(BXH 被盗案)，值得一提的是此案件受害者曾邀请10余家安全公司参与，比特丛林仅用三天提供高价值线索对案件进行定性并获取到受害者百万人民币奖励，可见比特丛林在数字资产溯源领域领先程度</span>",
        decribe4: "<span style='line-height:29px;'>2019年比特丛林协助某地警方破获USDT假充值案件，为受害者挽回价值1000万人民币的<br /><br />2021年协助某地警方破获利用智能合约盗取数字资产案件，并帮用户挽回被盗的数字资产</span>",
      }

    },
    ...zhLocale,
  },
};
const i18n = new VueI18n({
  locale: localStorage.getItem("lang")||"zh", // set locale
  messages, // set locale messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

router.beforeEach((to, from, next)=>{
  const accessToken = localStorage.getItem("accessToken");
  if(!accessToken && to.path!="/"){
    next({ path: '/' })
  }else{
    next()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
