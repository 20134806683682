<template>
  <div id="app">
    <div class="header-container">
      <div class="bit-header">
        <div class="bit-header-img">
          <img
            src="@/assets/common/icon-header.png"
            height="30px"
          />
        </div>
        <div class="bit-header-tab">
          <header-tab @showLoginDia="loginDia = true" ref="headerTab"/>
        </div>
        <div class="bit-header-user">
          <el-dropdown v-if="accessToken" class="dropdown-img" placement="bottom">
            <span class="el-dropdown-link">
              <img src="@/assets/common/icon-header-user.png" width="22px" height="21px" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <img v-else src="@/assets/common/icon-header-user.png" width="22px" height="21px" />

          <span class="bit-header-user-icon" @click="loginDia = true" v-if="!accessToken">{{
            this.$t("login")
          }}</span>
          <span class="bit-header-user-icon" v-else>{{ username }}</span>
        </div>
        <div class="bit-header-language">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ language == "zh" ? "简体中文" : "English"
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="dropdownHandler('zh')">简体中文</el-dropdown-item>
              <el-dropdown-item @click.native="dropdownHandler('en')">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="main-container" ref="mainContainer">
      <router-view @scrollToBottom="scrollToBottom" @showLoginDia="loginDia = true" />
    </div>

    <el-dialog
      :visible="loginDia"
      width="320px"
      :before-close="handleClose"
      :destroy-on-close="true"
    >
      <wx-login @wxLoginSuccess="wxLoginSuccess" v-if="loginDia" />
    </el-dialog>
  </div>
</template>

<script>
import HeaderTab from "@/components/HeaderTab";
import WxLogin from "@/views/login/WxLogin";
export default {
  name: "app",
  components: {
    HeaderTab,
    WxLogin,
  },
  data() {
    return {
      language: localStorage.getItem("lang") || "zh",
      loginDia: false,
      accessToken: localStorage.getItem("accessToken"),
      username: "",
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo")) {
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
    }
  },
  methods: {
    dropdownHandler(language) {
      localStorage.setItem("lang", language);
      this.$i18n.locale = language;
      window.location.reload();
    },
    scrollToBottom() {
      var container = this.$refs.mainContainer;
      container.scrollTop = container.scrollHeight;
    },
    goLogin() {
      this.$router.push("login");
    },
    handleClose() {
      this.loginDia = false;
    },
    wxLoginSuccess() {
      this.loginDia = false;
      this.accessToken = localStorage.getItem("accessToken");
      this.username = JSON.parse(localStorage.getItem("userInfo")).name;
      // 设置登录状态
      localStorage.setItem("isLogin",1)
    },
    logout() {
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.accessToken = null;
        localStorage.setItem("isLogin",0)
        this.username = "";
        this.$router.push("/");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/style.css";
#app {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  .header-container {
    // width: 1280px;
    background-color: #fff;
    margin: 0 24px;
    height: 60px;

    .bit-header {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      height: 60px;
      &-img {
        flex: 1;
        img {
          // margin-left: 79px;
        }
      }
      &-tab {
        width: 300px;
        text-align: right;
      }
      &-user {
        height: 60px;
        display: flex;
        align-items: center;
        color: #2a3451;
        font-size: 14px;
        margin-right: 20px;
        img {
          margin-right: 8px;
        }
        &-icon {
          cursor: pointer;
        }
      }
      &-language {
        // margin-right:79px;
        width: 80px;
        /deep/.el-dropdown {
          cursor: pointer;
        }
      }
    }
  }
  .main-container {
    flex: 1;
    display: flex;
    overflow-y: scroll;
    min-width: 1280px;
  }
  /deep/.el-dropdown.dropdown-img {
    width: 22px;
    height: 21px;
    margin-right: 8px;
  }
}
</style>
